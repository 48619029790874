import { HttpErrorResponse } from '@angular/common/http';

export type RequestAllowedHttpMethods = 'get' | 'post';

export const API_CONFIG = {
	pageSize: 50
};

export interface PagedApiRequestParams {
	pageSize?: number;
	page?: number;
	sortBy?: string;
	sortDirection?: '' | 'asc' | 'desc';
	search?: string;
}

export interface ApiRequestConfig {
	url: string;
	httpMethod: RequestAllowedHttpMethods;
}

export interface GeneralParams {
	[key: string]: any;
}

export interface PagedApiRequestParams {
	pageSize?: number;
	page?: number;
	sortBy?: string;
	sortDirection?: '' | 'asc' | 'desc';
	search?: string;
}

// same as PagedApiRequestParams with all properties matching API on backend
export interface PagedListApiRequestParams {
	sortBy?: string;
	sortDirection?: '' | 'asc' | 'desc';
	search?: string;
	limit?: number;
	offset?: number;
}

export interface ApiResponse<T> {
	data: T;
}

export interface PagedResponse<T> {
	list: T[];
	next: number;
	previous: number;
	total: number;
}

export interface ResponseWrapper<T> {
	data?: T;
	error?: {
		code: number;
		message: string;
	};
}

export interface PagedResponseWrapper<T, K extends PagedResponse<T> = PagedResponse<T>> extends ResponseWrapper<K> {
	data: K;
}

export interface CustomApiError {
	code: number;
	message: string;
}

export interface CustomHttpErrorResponse extends HttpErrorResponse {
	error: {
		error: CustomApiError;
	};
}

export interface DropdownPagedApiResponseDefaultState<T> {
	list: T[];
	next: number;
	previous: number;
	total: number;
	loading: boolean;
	error: string;
}

export const pagedApiResponseDefaultState = {
	list: null,
	next: null,
	previous: null,
	total: null,
	loading: false,
	error: null
} as DropdownPagedApiResponseDefaultState<any>;

export interface TypedHttpErrorResponse extends HttpErrorResponse {
	error: { message: string };
}

export enum QueryDataStatus {
	NoData = 'NoData',
	InProgress = 'InProgress',
	OK = 'OK',
	Error = 'Error'
}

export const FETCHING_DATA_INTERVAL_TIME = 2000;

export class InProgressError extends Error {
	constructor(msg?: string) {
		super(msg);
		Object.setPrototypeOf(this, InProgressError.prototype);
	}
}
